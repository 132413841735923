import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
// import Logo from "../../assets/Tag8logofinal.png";
import "./header.component.css";
import { Link } from "react-router-dom";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import * as BiIcons from "react-icons/bi";
// import UserImage from "../../assets/user2.png";
// import qrcode from "../../assets/qr-code.png";
import { DialogActions, DialogTitle } from "@material-ui/core";
import * as RiIcons from "react-icons/ri";
import jwt_decode from "jwt-decode";
import Button from "@material-ui/core/Button";
import { QrReader } from "react-qr-reader";
import { switchUrls } from "../../api/index.js";

const baseURL = switchUrls("gateway");

function readCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
let usertoken = readCookie("token");

function delete_cookie(name) {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}

function Navbar() {
  const token = usertoken;
  const [isAuth, setIsAuth] = useState(usertoken !== null ? true : false);
  const [passwordType, setPasswordType] = useState("password");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const [form, setForm] = useState("none");
  const [form1, setForm1] = useState("none");
  const [firstName, setFirstName] = useState(" ");
  const [lastName, setLastName] = useState(" ");
  const [uid, setUID] = useState("");
  const [navbar, setNavbar] = useState(null);
  const [ownerID, setOwnerID] = useState("");
  const [parentImage, setParentImage] = useState();
  const [tagNotFound, setTagNotFound] = useState(false);
  const [alreadyActivated, setAlreadyActivated] = useState(false);
  const handleNotFound = () => setTagNotFound(false);
  const handleActivated = () => setAlreadyActivated(false);
  const [changePassword, setChangePassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [characterLength, setCharacterLength] = useState("none");
  const [validPassword, setValidPassword] = useState("none");
  const [passwordValid, setPasswordValid] = useState(false);
  const [message, setMessage] = useState("");
  const [alert, setAlert] = useState("none");
  const [show, setShow] = useState(false);
  const [show5, setShow5] = useState(false);
  const [tagNumber, setTagNumber] = useState("");

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const passwordRE = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\w\W]{8,}$/;
  function activateTag() {
    const state = {
      tag_number: ownerID,
    };
    axios
      .post(`${baseURL}/api/checkTagNumberWithoutHeader`, state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data === "Tag not Found by the specified Number") {
          setTagNotFound(true);
        } else if (res.data === "Tag is already activated") {
          setAlreadyActivated(true);
        } else {
          const initial = ownerID.substring(0, 3);
          if (initial === "PET") {
            window.location.href = "/pet-activation/" + ownerID;
          } else if (initial === "KEY") {
            window.location.href = "/key-activation/" + ownerID;
          }
          if (initial === "TGZ") {
            window.location.href = "/pet-activation/" + ownerID;
          } else if (initial === "TGY") {
            window.location.href = "/key-activation/" + ownerID;
          } else if (initial === "CLB") {
            window.location.href = "/bag-activation/" + ownerID;
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  function reportFound() {
    window.location.href = "/scan/" + ownerID;
  }
  function logout(event) {
    delete_cookie("token");
    setIsAuth(false);
  }
  const renderQRModal = () => {
    return (
      <Dialog
        open={show5}
        fullWidth
        maxWidth="xs"
        onClose={() => setShow5(false)}
        aria-labelledby={"Scan QR"}
      >
        <div>
          <QrReader
            onResult={(result, error) => {
              if (result) {
                setOwnerID(
                  result?.text.split("/")[result?.text.split("/").length - 1]
                );
                setShow5(false);
              }

              if (error) {
                console.info(error);
              }
            }}
            style={{ width: "100%" }}
          />
        </div>
      </Dialog>
    );
  };
  function capitalizeName(name) {
    return name.replace(/\b(\w)/g, (s) => s.toUpperCase());
  }
  function updatePassword() {
    const state = {
      password: password,
      email: email,
    };
    axios
      .post(`${baseURL}/api/updatePassword`, state, {
        headers: {
          Authorization: token,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.data === "Password updated successfully") {
          setChangePassword(false);
          setMessage("Password updated succesfully");
          setAlert("flex");
          setPassword("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    if (isAuth) {
      axios
        .get(`${baseURL}/api/getUserProfile`, {
          headers: {
            Authorization: usertoken,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          localStorage.setItem("uid", res.data.uid);
          setUID(res.data.uid);
          localStorage.setItem(
            "name",
            res.data.first_name + " " + res.data.last_name
          );
          setFirstName(res.data.first_name);
          setLastName(res.data.last_name);
          setEmail(res.data.email);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);
  function formValid() {
    return passwordValid;
  }
  useEffect(() => {
    if (password.length > 7 || password === "") {
      setCharacterLength("none");
      if (passwordRE.test(password)) {
        setPasswordValid(true);
        setValidPassword("none");
      } else if (password !== "") {
        setPasswordValid(false);
        setValidPassword("block");
      } else {
        setPasswordValid(false);
        setValidPassword("none");
      }
    } else {
      setValidPassword("block");
      setCharacterLength("block");
      setPasswordValid(false);
    }
    setSubmitDisabled(!formValid());
  });
  const valid = (item) => {
    let password = document.querySelector(`#${item}`);
    password.style.display = "none";
  };
  //If Form Not Valid
  const invalid = (item) => {
    let password = document.querySelector(`#${item}`);
    password.style.display = "block";
  };
  useEffect(() => {
    if (alert === "flex") {
      setTimeout(() => setAlert("none"), 8000);
    }
  });

  const showNavbar = (e) => {
    e.preventDefault();

    setNavbar(!navbar);
  };

  useEffect(() => {
    if (usertoken) {
      setInterval(() => {
        var decoded = jwt_decode(usertoken);
        const expiry = decoded.exp * 1000 - 60000;
        if (Date.now() >= expiry) {
          delete_cookie("token");
          window.location.href = "/login";
        }
      }, 1000);
    }
  });
  return (
    <header className="header-section">
      <a className="logo" href="https://wallet.tag8.in/">
        <img
          src={
            "https://storage.googleapis.com/pettag/qr/assets/Tag8-Logo-slst--black.png"
          }
          className="logo-image"
        />
      </a>

      <button
        className="navbar-toggle-btn"
        aria-controls="navbarSupportedContent"
        aria-label="Toggle navigation"
        onClick={showNavbar}
      >
        {navbar ? <BiIcons.BiMenuAltRight /> : <BiIcons.BiMenuAltLeft />}
      </button>

      <nav className={navbar ? "header-nav" : "header-nav-hide"}>
        <ul className="header-nav-ul">
          <li className="header-nav-ul-li">
            {/* {document.referrer === "/key" ? (
              <a href="/key" className="nav-link navbar__options">
                Home
              </a>
            ) : (
              <a href="/pet" className="nav-link navbar__options">
                Home
              </a>
            )} */}
            <a href="https://www.tag8.in/" className="nav-link navbar__options">
              Home
            </a>
          </li>

          <li className="header-nav-ul-li">
            {/* {document.referrer === "/key" ? (
              <a
                href="https://tag8.in/tag8-store-new/public/tag8-faq/index.html"
                target="_blank"
                rel="noreferrer"
                className="nav-link navbar__options"
              >
                FAQ
              </a>
            ) : (
              <a href="/faq" className="nav-link navbar__options">
                FAQ
              </a>
            )} */}
            <a
              href="https://www.tag8.in/tag8-store-new/public/tag8-faq/index.html"
              target="_blank"
              rel="noreferrer"
              className="nav-link navbar__options"
            >
              FAQ
            </a>
          </li>

          <li className="header-nav-ul-li">
            <a
              className="nav-link navbar__options"
              target="_blank"
              href="https://www.tag8.in/tag8-store-new/public/index.php"
            >
              Store
            </a>
          </li>

          <li className="header-nav-ul-li">
            {!isAuth || firstName === "" ? (
              <a
                className="nav-link navbar__options"
                href="https://dashboard.tag8.in/#/login"
              >
                Login
              </a>
            ) : (
              <a
                className="nav-link navbar__options"
                href={`https://dashboard.tag8.in/#/dashboard/` + uid}
              >
                My Tags
              </a>
            )}
          </li>

          <li className="header-nav-ul-li">
            {!isAuth || firstName === "" ? (
              <a
                className="nav-link navbar__options"
                href="https://dashboard.tag8.in/#/signup"
              >
                Signup
              </a>
            ) : (
              <div className="dropdown hide-on-responsive topmargin-responsive">
                <Link to="#" className="dropbtn navbar__options">
                  <span>
                    <img
                      src={
                        parentImage
                          ? "parentImage"
                          : "https://storage.googleapis.com/pettag/qr/assets/user.png"
                      }
                      alt="User"
                      className="user-image"
                    />

                    {capitalizeName(firstName + " " + lastName)}

                    <i className="fa fa-caret-down ml-2" aria-hidden="true"></i>
                  </span>
                </Link>

                <div className="dropdown-content-1">
                  <a href="/#/profile" className="dropdown-item">
                    <center className="drop_down_nav">Profile</center>
                  </a>

                  {localStorage.getItem("logintype") === "manual" ? (
                    <Link
                      to="#"
                      onClick={() => setChangePassword(true)}
                      className="dropdown-item"
                    >
                      <center className="drop_down_nav">Change Password</center>
                    </Link>
                  ) : (
                    ""
                  )}

                  <a onClick={logout} href="/#/login" className="dropdown-item">
                    <center>Logout</center>
                  </a>
                </div>
              </div>
            )}
          </li>

          <li className="header-nav-ul-li">
            <div className="got-genie-form" style={{ display: form }}>
              <input
                style={{ padding: "-5px" }}
                placeholder="Owner ID"
                onKeyUp={(e) => {
                  e.target.value = e.target.value.toUpperCase();
                }}
                onChange={(e) => setOwnerID(e.target.value)}
              />

              <div className="genie-form-button">
                <button
                  className="light"
                  title={
                    !isAuth ? "Please login to activate tag" : "Activate Tag"
                  }
                  onClick={activateTag}
                  disabled={!isAuth}
                >
                  Activate
                </button>

                <button className="light" onClick={reportFound}>
                  Report Found
                </button>
              </div>
            </div>

            <button className="got-genie1" onClick={handleShow}>
              <span className="got-genie-top font-weight-bold">Got tag8?</span>
              <br />
              Register or Report
            </button>
          </li>
        </ul>

        <div className="got-genie-div-1">
          <button className="got-genie1" onClick={handleShow}>
            <span className="got-genie-top font-weight-bold">Got tag8?</span>
            Register or Report
          </button>

          <div className="got-genie-form" style={{ display: form }}>
            <input
              style={{ padding: "-5px" }}
              placeholder="Owner ID"
              onKeyUp={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              onChange={(e) => setOwnerID(e.target.value)}
            />

            <img
              src={"https://storage.googleapis.com/pettag/qr/qr-code.png"}
              className="qrbutton"
              alt="qr bar code"
              onClick={(e) => setShow5(true)}
            />

            <div className="genie-form-buttons">
              <Button
                variant="light"
                title={
                  !isAuth ? "Please login to activate tag" : "Activate Tag"
                }
                onClick={activateTag}
                disabled={!isAuth}
              >
                Activate
              </Button>

              <button variant="light" onClick={reportFound}>
                Report Found
              </button>
            </div>
          </div>
        </div>
      </nav>

      <Dialog
        disableBackdropClick
        open={tagNotFound}
        onClose={handleNotFound}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>{"Tag Number Not Found"}</DialogTitle>

        <DialogActions>
          <button className="active__btns" onClick={handleNotFound}>
            OK
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        open={alreadyActivated}
        onClose={handleActivated}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle>{"Tag is already activated"}</DialogTitle>

        <DialogActions>
          <button className="otp-buttons" onClick={handleActivated}>
            OK
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        disableBackdropClick
        maxWidth="xs"
        open={changePassword}
        onClose={() => setChangePassword(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent className="header-change-password-popup">
          <label>Change Password</label>

          <div className="header-change-password-popup-input-container">
            <input
              className="header-change-password-popup-input"
              value={email}
              readOnly
            />

            <span className="header-change-password-popup-checkbox-input-container">
              <input
                className=""
                type={passwordType}
                placeholder="Password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);

                  const txt = e.target.value;

                  if (txt.length >= 8) {
                    valid("islen");
                  } else {
                    invalid("islen");
                  }

                  if (txt.match(/[A-Z]/) != null) {
                    valid("capital");
                  } else {
                    invalid("capital");
                  }

                  if (txt.match(/[a-z]/) != null) {
                    valid("small");
                  } else {
                    invalid("small");
                  }

                  if (txt.match(/[0-9]/) != null) {
                    valid("isnum");
                  } else {
                    invalid("isnum");
                  }
                }}
                required
              />

              <div>
                <span style={{ display: characterLength }} id="islen">
                  Password must be atleat 8 characters
                </span>

                <span style={{ display: validPassword }} id="issp">
                  Password must contain -{" "}
                </span>

                <span style={{ display: validPassword }} id="isnum">
                  Atleast one number
                </span>

                <span style={{ display: validPassword }} id="capital">
                  Atleast one uppercase letter
                </span>

                <span style={{ display: validPassword }} id="small">
                  Atleast one lowercase letter
                </span>
              </div>

              <div className="header-change-password-popup-checkbox-container">
                <input
                  id="header-change-password-checkbox"
                  type="checkbox"
                  className="header-change-password-popup-checkbox"
                  onClick={() =>
                    setPasswordType(
                      passwordType === "password" ? "text" : "password"
                    )
                  }
                />

                <label for="header-change-password-checkbox">
                  <RiIcons.RiLockPasswordFill />
                </label>
              </div>
            </span>
          </div>

          <div className="header-change-password-popup-btns">
            <button
              disabled={submitDisabled}
              onClick={updatePassword}
              style={{ backgroundColor: "#ffc107" }}
            >
              Submit
            </button>

            <button
              onClick={() => {
                setChangePassword(false);
                setEmail("");
                setPassword("");
              }}
            >
              Cancel
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        PaperProps={{
          style: { borderRadius: 15 },
        }}
        disableBackdropClick
        open={show}
        fullWidth
        maxWidth="xs"
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <h2 className="header-activateTag-popup">Tag Activation</h2>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <input
              className="keyTag-header-input__field__dialogs"
              onKeyUp={(e) => {
                e.target.value = e.target.value.toUpperCase();
              }}
              value={tagNumber ? tagNumber : ""}
              onChange={(e) => setTagNumber(e.target.value)}
              placeholder="Owner ID"
            />

            <img
              src={"https://storage.googleapis.com/pettag/qr/qr-code.png"}
              className="qrbutton"
              onClick={(e) => setShow5(true)}
            />
          </div>

          <div
            style={{
              padding: "1em",
              marginBottom: "1px",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
          >
            <button className="header-verifyOtp-button" onClick={activateTag}>
              Activate
            </button>

            <button className="header-otp-buttons" onClick={handleClose}>
              Cancel
            </button>
          </div>
        </DialogContent>
      </Dialog>

      <div className="AlertSignup" style={{ display: alert }}>
        <p>{message}</p>

        <button onClick={() => setAlert("none")}>Cancel</button>
      </div>

      {renderQRModal()}
    </header>
  );
}

export default Navbar;
